body {
  font-family: "Lexend", sans-serif;
  height: 100%;
}

.welcome {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
@font-face {
  font-family: "Inter_28pt-Regular";
  src: url("../assets/fonts/Inter_28pt-Regular.ttf");
}
@font-face {
  font-family: "Inter_18pt-SemiBold";
  src: url("../assets/fonts/Inter_18pt-SemiBold.ttf");
}
@font-face {
  font-family: "Inter_18pt-ExtraBold";
  src: url("../assets/fonts/Inter_18pt-ExtraBold.ttf");
}
@font-face {
  font-family: "Inter_24pt-Medium";
  src: url("../assets/fonts/Inter_24pt-Medium.ttf");
}
@font-face {
  font-family: "Inter_28pt-Black";
  src: url("../assets/fonts/Inter_28pt-Black.ttf");
}
@font-face {
  font-family: "Inter_24pt-Light";
  src: url("../assets/fonts/Inter_24pt-Light.ttf");
}
@font-face {
  font-family: "Inter_24pt-Bold";
  src: url("../assets/fonts/Inter_24pt-Bold.ttf");
}
* {
  font-family: "Inter_28pt-Regular";
}
/* ============================================(  Login Form  )=========================================== */

.login-form {
  display: flex;
  align-items: center;
  height: 100vh;
}

.login-layout {
  background-color: #000;
  border-radius: 20px;
  padding: 3rem;
}

.validatior-error {
  color: red;
  font-size: 13px;
}

.input {
  position: relative;
}

.login-eye {
  position: absolute;
  right: 3%;
  top: 32%;
}

/* ===========================================(   Sidebar   )================================================= */
.sidebar-menu {
  cursor: pointer;
}

.sidebar-menu > p {
  margin: 4px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ----------------sidebar-scroll---------------------- */
.menus::-webkit-scrollbar,
.main-page::-webkit-scrollbar,
.Admin-form-content::-webkit-scrollbar {
  display: none;
}

/* =============================( page content occupying full available height   )========================= */

.main-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  border-radius: 5px;
}

.page-content {
  flex: 1;
}

.layout-container {
  padding: 1.5rem;
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #000;
}

.content-body {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

/* =============================( To position sidebar-header-page content  )================================ */

.sidebar {
  width: 17rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  margin-right: 1rem;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  color: #757575;
  border-radius: 5px;
}

/* Scrollbar visible only on hover */
.menus {
  flex: 1;
  /* occupy available remaining space*/
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.menus:hover {
  flex: 1;
  overflow-y: scroll;
}

/* =================================== */
.header {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.page-content {
  background-color: #f5f5f5;
}

.profile-image {
  width: 2.5rem;
  height: auto;
  border: 1px solid white;
  border-radius: 50%;
}

/* ===========================================( Tooltip CSS )========================================== */
.tootip-test {
  position: absolute;
  transform: translate(60px);
  display: none;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
}

.sidebar-menu:hover + .tootip-test {
  display: block;
}

.dropdown-toggle::after {
  display: none;
}

.login-bg {
  background-color: #b3ff79;
  height: 100vh;
  display: flex;
  align-items: center;
}

.login-btn {
  background-color: #b3ff79;
  font-weight: bold;
  font-size: 1.2rem;
}

.login-btn:hover {
  border: 1px solid #b3ff79;
  color: #fff;
  background-color: #000;
}

.login-input {
  padding: 10px;
  font-size: 1rem;
}

.sidebar-menu-wrapper {
  display: flex;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
}

.sidebar-menu-wrapper:hover,
.clicked {
  background-color: #b3ff79;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
}

.admin-logo {
  height: 3rem;
  width: auto;
}

.sidebar-header {
  background-color: #60ffcb;
  text-align: center;
  color: #000;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 5px;
}

.admin-panel-input {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  color: white;
  border: 1px solid gray;
}

.admin-btn {
  background-color: #60ffcb;
  border: none;
  font-size: 1.1rem;
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 10px;
  border: 1px solid #60ffcb;
}

.admin_btn_right {
  display: flex;
  justify-content: flex-end;
}

.admin-btn:hover {
  background-color: transparent;
  color: #60ffcb;
  border: 1px solid #60ffcb;
}

.admin-btn-expert {
  background-color: #60ffcb;
  border: none;
  font-size: 1.1rem;
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 10px;
}

.validation-error {
  color: #ef7878;
  font-size: 12px;
}

.admin_input_div {
  display: flex;
}

.create_admin_div {
  --tw-bg-opacity: 1;
  background-color: rgb(47 47 47 / var(--tw-bg-opacity));
  padding: 45px;
}

.admin_input label {
  color: #60ffcb;
  margin-bottom: 3px;
}

.admin_input {
  display: flex;
  flex-direction: column;
}

.admin_input input {
  width: 100%;
  padding: 7px;
  padding-left: 12px;
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(47 47 47 / var(--tw-bg-opacity));
  border: 1px solid white;
  border-radius: 5px;
}

.admin_input input:focus {
  outline: none;
}

.create_admin_head {
  color: #60ffcb;
  font-weight: 600;
  margin-bottom: 20px;
}

.password_hint p {
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
}

/* ===========================================( Mui-Table css )========================================== */

.css-11mde6h-MuiPaper-root,
.css-1ygcj2i-MuiTableCell-root,
.admin-content-body {
  background: #ffffff11 !important;
}

.css-11mde6h-MuiPaper-root {
  border-radius: 5px;
}

.css-2ulfj5-MuiTypography-root,
.tss-1akey0g-MUIDataTableHeadCell-data,
.tss-bd2lw8-MUIDataTableHeadCell-sortActive {
  color: #60ffcb !important;
}

.tss-1qtl85h-MUIDataTableBodyCell-root,
.tss-1dccma1-MUIDataTablePagination-toolbar,
.tss-10rusft-MUIDataTableToolbar-icon,
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: #d6d6d6 !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar > p {
  margin-bottom: 0;
}

.css-nfmglb-MuiTableCell-root {
  border: none !important;
}

.css-1ex1afd-MuiTableCell-root {
  border-bottom: 1px solid rgb(55 54 54) !important;
  text-align: center !important;
}

.css-1ygcj2i-MuiTableCell-root {
  border: none !important;
}

.tss-178gktx-MUIDataTableHeadCell-contentWrapper {
  display: block !important;
  text-align: center;
}

.tss-hwdp7s-MUIDataTable-liveAnnounce {
  display: none !important;
}

.admin-content-body {
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.admin-panel-label {
  color: #ffffff80;
  margin-bottom: 0.75rem;
}

.admin-form-heading {
  color: #60ffcb;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 1rem 0;
}

.admin-form-subheading {
  color: #757575;
  font-weight: bold;
  font-size: 1.3rem;
  text-decoration: underline;
}

.admin-form-container {
  padding-bottom: 2rem;
}

.Admin-form-content {
  flex-grow: 1;
  overflow-y: scroll;
  padding-top: 1rem;
  overflow-x: hidden;
}

.admin-delete-icon {
  color: #ef7878;
  font-size: 21px;
  cursor: pointer;
}

.update_btn_icon {
  color: #d6d6d6 !important;
  font-size: 21px;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  margin: 0px 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #767070;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4ca363;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4ca363;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.admin-view-icon {
  font-size: 18px;
  cursor: pointer;
}

.tab_head {
  color: white;
  font-weight: 600;
  font-size: 15px;
  padding: 5px 15px;
  cursor: pointer;
  margin-right: 10px;
}

.tab_head:hover {
  background-color: #60ffcb;
  color: black;
  border-radius: 5px;
}

.tab_head_active {
  background-color: #60ffcb;
  color: black;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  padding: 5px 15px;
  margin-right: 10px;
}

/* .css-1ygcj2i-MuiTableCell-root {
  padding: 15px 12px !important;
} */
/* ===========================================( Landing Page css )========================================== */
.nav-flex {
  display: flex;
  justify-content: space-between;
  padding: 0px 55px;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 0 !important;
  align-items: center;
}

.logo-img {
  width: 150px;
  height: auto;
}

.parent-section {
  /* height: 100vh; */
  background: url("../assets/images//banner-back.png");
  /* background-position: right; */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.nav-item a {
  color: white;
  margin-right: 15px;
}

.create-btn {
  padding: 6px 20px;
  background-color: #60ffcb;
  border: none;
  border-radius: 4px;
  color: #202428;
  font-weight: 500;
  margin-left: 35px;
  font-family: "Inter_18pt-SemiBold";
}

.create-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  /* Inherits the background color */
  z-index: -1;
  /* Places it behind the button text */
  transition: filter 0.3s ease;
}

.create-btn:hover::before {
  filter: blur(5px);
  /* Apply blur to the background */
}

.create-btn:hover {
  transform: translateY(-5px);
  /* Move the button slightly up */
  transition: transform 0.3s ease;
  /* Smooth transition for the movement */
}

.banner-head1 {
  font-size: 55px;
  font-weight: 700;
  color: #ffffff;
  font-family: "Inter_24pt-Bold";
}

.banner-head2 {
  font-size: 55px;
  font-weight: 700;
  color: #60ffcb;
  font-family: "Inter_24pt-Bold";
}

.head-para {
  color: white;
  font-size: 11px;
}

.banner-input {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border: none;
  padding: 8px 20px;
  border-radius: 20px;
  width: 260px;
  color: white;
}

.fa-arrow-right {
  background: #5fffca33;
  color: #60ffcb;
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  left: 220px;
  transform: rotate(-39deg);
  font-size: 15px;
}

.fa-circle-info {
  color: #60ffcb;
  font-size: 14px;
}

.ban-left-img {
  width: 17rem;
  height: auto;
  z-index: 100;
  padding-top: 10px;
  animation: smooth-up-down 2s ease-in-out infinite;
}

@keyframes smooth-up-down {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.ban-bottom {
  width: 30rem;
  height: auto;
  position: absolute;
  bottom: -68px;
}

.banner-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.banner-right {
  padding: 50px 0px;
}

.counter-div1 {
  display: flex;
  justify-content: space-around;
}

.counter {
  font-size: 45px;
  font-weight: 700;
  color: white;
  margin-bottom: 0px;
}

.section-2 {
  background-color: black;
  background: url("../assets/images/section2_background.png");
  /* background-position: right; */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.counter-para {
  font-size: 12px;
  text-align: center;
  color: white;
}

/* .section2-right {
  margin: 60px 0px 0px;
} */

.counter-box1 {
  padding-bottom: 30px;
}

.lin1-vertical {
  position: absolute;
  top: 0%;
  right: 51%;
  height: 70px;
}

.line1-horizontal {
  width: 90px;
}

.sec-left-img {
  width: 50%;
  height: auto;
  padding: 80px 0px 0px;
  /* animation: zoomBounce 3s infinite ease-in-out; */
}

.about-points {
  display: flex;
}

.aboutus-section {
  /* height: 100vh; */
  background: url("../assets/images/about-back.png");
  /* background-position: right; */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.about-left-img {
  width: 50%;
  height: auto;
  z-index: 100;
  animation: smooth-up-down 2s ease-in-out infinite;
}

@keyframes zoomBounce {
  0%,
  100% {
    transform: scale(1);
    /* Original size */
  }

  50% {
    transform: scale(1.1);
    /* Zoomed in */
  }

  70% {
    transform: scale(0.9);
    /* Slightly smaller, creating a bounce effect */
  }
}

.get-left-img {
  width: 50%;
  height: auto;
  z-index: 100;
  animation: smooth-up-down 2s ease-in-out infinite;
}

.user-left-img {
  width: 75%;
  height: auto;
  z-index: 100;
  animation: smooth-up-down 2s ease-in-out infinite;
}

.expert-right-img {
  width: 75%;
  height: auto;
  z-index: 100;
  animation: smooth-up-down 2s ease-in-out infinite;
}

.user-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-left-line {
  position: absolute;
  height: 3px;
  top: 60%;
  left: 40%;
}

.user-right-line {
  position: absolute;
  height: 3px;
  top: 60%;
  left: 54%;
}

.expert-right-line {
  position: absolute;
  height: 3px;
  top: 60%;
  left: 55%;
}

.expert-left-line {
  position: absolute;
  height: 3px;
  top: 60%;
  left: 39%;
}

.our_journey_left {
  position: absolute;
  height: 3px;
  top: 65%;
  left: 41%;
}

.our_journey_right {
  position: absolute;
  height: 3px;
  top: 65%;
  left: 56%;
}

.abt-head {
  font-weight: 700;
  color: white;
}

.fa-check {
  background: #60ffcb33;
  color: #60ffcb;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}

.abt-para {
  color: white;
  font-weight: 500;
  margin-left: 13px;
  margin-bottom: 25px;
}

.download-btn {
  font-weight: 600;
  border: 1px solid #ffffff;
  width: 210px;
  color: white;
  border-radius: 20px;
  padding: 7px 15px 7px;
  cursor: pointer;
  font-family: "Inter_18pt-SemiBold";
}

.download-btn:hover {
  font-weight: 600;
  border: 1px solid black;
  width: 210px;
  color: rgb(0, 0, 0);
  border-radius: 20px;
  padding: 7px 15px 7px;
  background-color: rgb(96, 255, 203) !important;
  cursor: pointer;
  font-family: "Inter_18pt-SemiBold";
}
.arrow-2 {
  background: #ffffff;
  color: #000000;
  width: 28px;
  height: 28px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 176px;
  transform: rotate(-39deg);
  font-size: 15px;
  cursor: pointer;
}

.aboutus-section {
  padding: 60px 0px;
}

.abt-head2 {
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 90px;
}

.platform-usage-section {
  background-color: black;
  /* height: 100vh; */
  padding: 60px 0px;
}

.top-card {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border: 1.06px solid;
  border-image-source: linear-gradient(
    92.64deg,
    rgba(255, 255, 255, 0.5) -13.07%,
    rgba(255, 255, 255, 0) 6.86%,
    rgba(255, 255, 255, 0) 88.43%,
    rgba(255, 255, 255, 0.21) 104.39%
  );

  padding: 20px 30px;
  border-radius: 15px;
}

.card-head {
  color: white;
  /* margin-bottom: 0px; */
  font-size: 25px;
}

.card-para {
  color: white;
  font-size: 12px;
}

.chartline {
  width: 50px;
  height: auto;
  padding-bottom: 20px;
}

.platform-center {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  height: 455px;
  border: 1.06px solid;
  border-image-source: linear-gradient(
    92.64deg,
    rgba(255, 255, 255, 0.5) -13.07%,
    rgba(255, 255, 255, 0) 6.86%,
    rgba(255, 255, 255, 0) 88.43%,
    rgba(255, 255, 255, 0.21) 104.39%
  );
  border-radius: 15px;
}

.forgot-password-text {
  cursor: pointer;
  /* padding: 20px 30px; */
  border-radius: 15px;
}

.paltform-center-img {
  width: 275px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.platform-logo {
  width: 155px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 34px 0px;
}

.abt-head3 {
  font-weight: 700;
  color: #60ffcb;
  text-align: center;
  margin-bottom: 90px;
}

.abt-head4 {
  font-weight: 700;
  color: #b3ff79;
  text-align: center;
  margin-bottom: 90px;
}
.abt-head_video {
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
}

.user-section {
  /* height: 100vh; */
  background: url("../assets/images/about-back.png");
  /* background-position: right; */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 60px 0px;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 10px !important;
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
.user-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-flex {
  display: flex;
}

.user-card {
  width: 200px;
}

.user-card1 {
  width: 200px;
  margin-right: 35px;
  margin-bottom: 35px;
}

.user-card-img {
  width: 35px;
  height: auto;
  padding-bottom: 20px;
}

.card-para-2 {
  color: white;
  font-size: 10px;
  padding-top: 7px;
}

.line2-horizontal {
  width: 180px;
  height: 3px;
}

.user-line-vertical {
  position: absolute;
  top: 0%;
  right: -2%;
  height: 130px;
  width: 2px;
}

.Expert-section {
  /* height: 100vh; */
  background: url("../assets/images/expert-back.png");
  /* background-position: right; */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 50px 0px 10px;
}

.market-section {
  padding: 0px 0px 0px;
  background: url("../assets/images/section2_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.market-left-img {
  width: 75%;
  height: auto;
  padding: 80px 0px 0px;
  /* animation: zoomBounce 3s infinite ease-in-out; */
}

.section2-left {
  display: flex;
  justify-content: center;
}

.watchdemo-section {
  background-color: black;
  padding: 60px 0px;
}

.video-head {
  color: white;
  font-weight: 700;
  padding-bottom: 5px;
}

.video-para {
  color: white;
  font-size: 13px;
  margin-bottom: 0px;
}

.video-hover:hover {
  background-color: #60ffcb33;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.video-hover_active {
  background-color: #60ffcb33;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  padding: 20px;
}
.video-number {
  background: #60ffcb33;
  color: #60ffcb;
  width: 31px;
  height: 31px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
}

.video-hover {
  display: flex;
  padding: 20px;
}

video[poster] {
  object-fit: cover;
}
.watch-demo {
  height: 100% !important;
}
.watch-demo video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.watchdemo-right {
  margin-left: 32px;
}

.faq_section {
  background-color: black;
  padding: 20px 0px 60px;
}

.faq_head {
  font-weight: 700;
  text-align: center;
  color: #60ffcb;
}

.user_faq {
  background: #60ffcb1a;
  padding: 30px;
  border-radius: 15px;
}

.accordion {
  --bs-accordion-bg: none !important;
  --bs-accordion-border-color: none !important;
}

.accordion-button:not(.collapsed) {
  color: white !important;
  box-shadow: none !important;
  background-color: var(--bs-accordion-btn-bg);
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button {
  color: white;
  font-weight: 700;
  border-bottom: 1.5px solid #313f3a;
  margin-bottom: 15px;
}
.accordion-button_last {
  border-bottom: none;
  margin-bottom: 0px !important;
}
.expert_faq {
  background: #b3ff791a;
  padding: 30px;
  border-radius: 15px;
}

.faq_head2 {
  font-weight: 700;
  text-align: center;
  color: #b3ff79;
}

.accordion-item {
  color: white !important;
}

.accordion-button::after {
  background-image: url("../assets/images/plus.png");
  transform: rotate(-180deg);
}

.accordion-button:not(.collapsed)::after {
  content: "";
  display: inline-block;
  background-image: url("../assets/images/faq_after_btn.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  /* Adjust width */
  height: 20px;
  /* Adjust height */
  transform: rotate(-180deg);
  /* transition: transform 0.3s ease; */
  /* Adds a smooth transition */
}

.our-head {
  color: white;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}

.our-subhead {
  color: white;
  text-align: center;
  font-weight: 700;
  margin-bottom: 0px;
}

.ourjourney_section {
  background-color: black;
}

.journey-year {
  color: white;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  font-size: 55px;
}

.journey-img {
  width: 80px;
  height: auto;
}

.journey_para {
  color: white;
}

.journey-top {
  margin-top: 70px;
}

.year-flex {
  /* display: flex; */
}

.journey_line {
  position: absolute;
  height: 58px;
  left: 50%;
  margin-top: 10px;
  z-index: 1;
}

.journey-details {
  display: flex;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  padding: 20px;
  border-radius: 15px;
  z-index: 100;
}

.journey_arrow_right {
  position: absolute;
  height: 65px;
  left: 50%;
  z-index: 1;
}

.journey_arrow_left {
  position: absolute;
  height: 65px;
  left: 50%;
  z-index: 1;
}

.ourjourney_section {
  padding: 0px 0px 60px;
}

.get_in_touch_section {
  background-color: black;
}

.abt-head5 {
  font-weight: 700;
  color: white;
}

.form_input {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.form_input label {
  color: white;
  font-weight: 500;
}

.form_input input {
  /* height: 38px; */
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border: none;
  border-radius: 5px;
  padding: 10px;
  padding-left: 20px;
  color: white;
}
.form_input input::placeholder {
  font-size: 14px;
}
.form_input textarea {
  height: 85px;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border: none;
  border-radius: 5px;
  padding-left: 20px !important;
  padding: 10px;
  color: white;
}
.form_input textarea::placeholder {
  font-size: 14px;
}
.form_input input:focus {
  outline: none;
}

.form_input textarea:focus {
  outline: none;
}

.get_in_touch_section {
  background: url("../assets/images/about-back.png");
  /* background-position: right; */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 60px 0px 60px;
}

.get_touch_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 90px !important;
}

.footer_img {
  width: 150px;
  height: auto;
}

.footer_section {
  background-color: black;
  padding: 30px 0px;
}

.socail-icon {
  width: 40px;
  height: auto;
  cursor: pointer;
}

.footer_top {
  display: flex;
  justify-content: space-between;
}

.footer_bototm {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.footer_bototm p {
  color: white;
  cursor: pointer;
}

.fa-envelope {
  color: #60ffcb;
  cursor: pointer;
}

.submit-btn {
  font-weight: 600;
  margin-top: 20px;
  width: 100%;
  border: none;
  background-color: #60ffcb;
  color: black;
  padding: 9px 0px;
  border-radius: 6px;
  cursor: pointer;
}

.nav-item a:hover {
  color: #60ffcb;
}

.style-9 {
  position: relative;
  white-space: nowrap;
  color: #000;
  /* font-family: aktiv-grotesk-extended, sans-serif;
  font-size: 24px; */
  text-decoration: none;
  cursor: pointer;

  &:hover {
    &:before {
      transform: translate3d(0, 2px, 0) scale3d(1.08, 3, 1);
      clip-path: polygon(
        0% 0%,
        0% 100%,
        50% 100%,
        50% 0,
        50% 0,
        50% 100%,
        50% 100%,
        0 100%,
        100% 100%,
        100% 0%
      );
    }

    span {
      transform: translate3d(0, -2px, 0);
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
    transform-origin: 50% 100%;
    transition: clip-path 0.3s, transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    clip-path: polygon(
      0% 0%,
      0% 100%,
      0 100%,
      0 0,
      100% 0,
      100% 100%,
      0 100%,
      0 100%,
      100% 100%,
      100% 0%
    );
  }

  span {
    display: inline-block;
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
  }
}

.style-21 {
  position: relative;
  z-index: 9;
  color: #000;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: -5px;
    background: hsl(45 100% 70%);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
  }
}

.banner-input:focus {
  outline: none;
}

.animation_overflow_banner {
  overflow: hidden;
  padding: 20px 0px;
}
.animation_overflow {
  overflow: hidden;
  padding: 80px 0px;
}
.animation_overflow_section2 {
  overflow: hidden;
  /* padding: 70px 0px 0px; */
}

.journey_line_left {
  position: absolute;
  width: 35px;
  left: 41%;
  top: 67%;
}

.journey_line_right {
  position: absolute;
  width: 35px;
  left: 56%;
  top: 67%;
}

.journey_img_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-toggler {
  text-decoration: none;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url("../assets//images/mobile-toggle.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.forgot-password-screen {
  background-color: #000;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 28rem;
}

.fp-logo {
  width: 16rem;
  height: auto;
  align-self: center;
  margin-bottom: 2rem;
}

.fp-btn {
  background-color: #b3ff79;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  padding: 10px;
}

.fb-text {
  font-size: 12px;
  color: #fff;
}

.otpSpace > div {
  justify-content: space-between;
}

.verify-otp-screen {
  width: 20rem;
}

.pointer {
  cursor: pointer;
}

.change-password-eye {
  position: absolute;
  right: 3%;
  top: 30%;
}

.resendBtn {
  background-color: transparent;
  border: none;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem;
}

.enquiryModal {
  width: 48rem;
}

.modal-close-btn {
  position: absolute;
  z-index: 100;
  top: 12px;
  right: 12px;
  font-size: 1.3rem;
}

.modal-close-btn:hover {
  color: gray;
}

.dropdown-menu.show {
  padding: 12px;
  width: 12rem;
}

.ok-btn {
  background-color: #60ffcb;
  border: 1px solid #60ffcb;
  width: 100%;
}

.ok-btn:hover {
  background-color: transparent;
  color: #60ffcb;
  border: 1px solid #60ffcb;
}

.cancel-btn {
  border: 1px solid #60ffcb;
  width: 100%;
}

.cancel-btn:hover {
  background-color: #60ffcb;
  color: black;
}

.logout-modal {
  width: 25rem;
  align-items: normal;
  background-color: #232222;
}

.text-green {
  color: #60ffcb;
}

.PhoneInputInput {
  padding: 10px;
  border-radius: 10px;
}

.PhoneInputCountry {
  padding: 3px 9px;
  background: white;
  border-radius: 10px;
}

.sun-editor .se-wrapper-wysiwyg {
  font-size: 1.2rem;
  font-family: sans-serif;
}

.sun-editor {
  height: 100%;
}

.sun-editor .se-wrapper .se-wrapper-inner {
  height: 100% !important;
}

.sun-editor .se-toolbar {
  position: sticky;
  top: 0;
}

.top-58 {
  top: 58%;
  color: #60ffcb;
}

.top-15 {
  top: 15%;
  color: #60ffcb;
}

.admin-panel-input::placeholder {
  color: rgb(160, 160, 160);
}

/* ===========================================( Expert tips css )========================================== */
.profile_parent_div {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  width: 350px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  height: 397px;
}

.prof_left img {
  height: auto;
  width: 65px;
  margin-right: 10px;
}

.profile_top_section {
  padding-bottom: 14px;
  border-bottom: 1.4px solid #3a3a3a;
}

.prof_right p {
  margin-bottom: 0px;
  color: white;
}

.expert_id {
  font-size: 10px;
}

.expert_name {
  font-weight: 600;
  font-size: 18px;
}

.rating_div {
  background-color: #ff7a00;
  border-radius: 3px;
  height: 17px;
  width: 29px;
}

.fa-star {
  color: white;
  font-size: 7px;
}

.rating_number {
  font-size: 12px;
  padding-left: 2px;
  font-weight: 600;
}

.category_div {
  background-color: #ff7a00;
  border-radius: 3px;
  height: 17px;
  width: 58px;
}

.total_text {
  color: white;
  font-weight: 600;
}

.folower_count {
  color: white;
  margin-top: 10px;
  font-size: 35px;
  font-weight: 600;
}

.prof_bottom_div p {
  margin-bottom: 0px;
}

.batch_text {
  color: black;
  font-size: 15px;
  font-weight: 600;
}

.batch_followers {
  color: black;
  font-size: 8px;
}

.expert_batch_div {
  background: #b3ff79;
  padding: 5px;
  border-radius: 30px;
}

.batch_img {
  height: auto;
  width: 30px;
  margin-top: 4px;
  margin-right: 7px;
}

.filter_text {
  color: #5d6166;
  background-color: #1a1a1a;
  padding: 3px 23px;
  border-radius: 15px;
  cursor: pointer;
}

.filter_text_active {
  color: #000000;
  background-color: #b3ff79;
  padding: 3px 23px;
  border-radius: 15px;
  cursor: pointer;
}

.fa-calendar-days {
  background: #b3ff7933;
  color: #b3ff79;
  padding: 7px 9px;
  border-radius: 5px;
  cursor: pointer;
}

.count_text {
  color: #000000;
  font-size: 35px;
  font-weight: 500;
}

.dash_card_text {
  color: #000000;
  font-size: 14px;
}

.dash_card {
  background-color: #b3ff79;
  border: 1.41px solid;
  border-radius: 15px;
  box-shadow: #212121 5px 5px;
  padding: 10px 0px 10px 15px;
}

.create_tip_card {
  border: 2px solid #b3ff79;
  padding: 25px 0px;
  border-radius: 17px;
  box-shadow: #66765a 4px 4px;
  cursor: pointer;
  animation: zoomInOut 2s infinite;
  transition: transform 0.2s;
}

.create_tip_card p {
  text-align: center;
  margin-bottom: 0px;
  color: #b3ff79;
}

.create_tip_card {
  transform: scale(1.1);
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.express_search {
  width: 100%;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );

  border: none;
  border-radius: 7px;
  padding: 10px;
  padding-left: 15px;
  color: white;
}

.express_search:focus {
  outline: none;
}

.exp_stock {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border-radius: 7px;
}

.expires_time {
  width: 100%;
  background-color: #b3ff79;

  border: none;
  border-radius: 7px;
  padding: 10px;
  padding-left: 15px;
  /* padding-right: 30px; */
  color: black;
  box-sizing: border-box;
}

.expires_time:focus {
  outline: none;
}

.expires_time:focus {
  outline: none;
}

.exp_stock_para {
  padding: 10px;
  margin-bottom: 0px;
  text-align: center;
  cursor: pointer;
  color: #5e6871;
}

.exp_stock_para_disabled {
  padding: 10px;
  margin-bottom: 0px;
  text-align: center;
  cursor: pointer;
  color: #5e6871;
  cursor: not-allowed;
  background-color: rgb(18, 17, 17);
  border-radius: 5px;
}

.exp_stock_para:hover {
  background: #b3ff79;
  border-radius: 7px;
  color: black;
  margin-bottom: 0px;
}

.exp_stock_para_active {
  background: #b3ff79;
  border-radius: 7px;
  color: black;
  margin-bottom: 0px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.only_price {
  padding: 10px;
  margin-bottom: 0px;
  cursor: pointer;
  text-align: center;
  color: white;
}

.buy_only_btn {
  width: 100%;
  background: #b3ff79;
  border: none;
  border-radius: 7px;
  padding: 10px;
  text-align: center;
  color: black;
  font-weight: 600;
}

.choose_scenario_dropdown {
  width: 100%;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );

  border: none;
  border-radius: 7px;
  padding: 13px;
  padding-left: 15px;
  color: #5e6871;
}

.choose_scenario_dropdown:focus {
  outline: none;
}

.choose_scenario_dropdown option {
  background-color: black !important;
  color: #5e6871 !important;
  padding: 10px !important;
}

.target_head {
  color: white;
}

.fa-circle-info2 {
  color: #b3ff79;
  font-size: 12px;
}

.head-para2 {
  color: white;
  font-size: 9px;
}

.target_indi_para {
  color: white;
  text-align: center;
}

.indicator_points {
  width: 100%;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border: none;
  border-radius: 7px;
  padding: 10px;
  color: #5e6871;
  text-align: center;
}

.inc_indicator {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border: none;
  border-radius: 10px;
  padding: 10px;
  color: #5e6871;
  text-align: center;
  width: 40px !important;
  position: absolute !important;
  top: 83px;
  cursor: pointer;
}

.dec_indicator {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border: none;
  border-radius: 10px;
  padding: 10px;
  color: #5e6871;
  text-align: center;
  width: 40px !important;
  position: absolute !important;
  top: 83px;
  right: 0px;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.create_tip_card2 {
  border: 2px solid #b3ff79;
  padding: 10px 0px;
  border-radius: 10px;
  cursor: pointer;
  animation: zoomInOut 2s infinite;
  transition: transform 0.2s;
}

.create_tip_card2 p {
  text-align: center;
  margin-bottom: 0px;
  color: #b3ff79;
}

.create_tip_card3 {
  border: 2px solid #b3ff79;
  padding: 10px 0px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #b3ff79;
}

.create_tip_card3 p {
  text-align: center;
  margin-bottom: 0px;
  color: black;
  font-weight: 600;
}

.create_tip_card:hover {
  animation: none;
  transform: scale(0.9);
}

.create_tip_card2:hover {
  animation: none;
  transform: scale(0.9);
}

.create_tip_card3:hover {
  animation: none;
  transform: scale(0.9);
}

.tip_tabs {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  color: #5e6871;
  padding: 10px 12px;
  width: 150px;
  margin-bottom: 0px;
  border-radius: 5px;
  cursor: pointer;
}

.tip_tabs_active {
  background: #b3ff79;
  color: black;
  padding: 10px 12px;
  width: 150px;
  margin-bottom: 0px;
  border-radius: 5px;
  cursor: pointer;
}

.tip_tabs:hover {
  background: #b3ff79;
  color: black;
  padding: 10px 12px;
  width: 150px;
  margin-bottom: 0px;
  border-radius: 5px;
  cursor: pointer;
}

.cat_stocks_select {
  width: 100%;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border: none;
  border-radius: 7px;
  padding: 13px;
  padding-left: 15px;
  padding-right: 15px;
  color: #5e6871;
}

.cat_stocks_select:focus {
  outline: none;
}

.chart_img {
  width: 30px;
  height: auto;
}

.chart_div {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border: none;
  border-radius: 7px;
  padding: 13px;
  width: 100%;
}

.chart_input {
  color: #5e6871;
}

.chart_upload_icon {
  background: #b3ff79;
  border: none;
  border-radius: 7px;
  padding: 13px 16px;
  cursor: pointer;
}

.chart_upload_icon i {
  color: black;
  font-size: 25px;
  cursor: pointer;
}

.fa-circle-info3 {
  color: #dc4453;
  font-size: 12px;
}

.filter_para {
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}

.close-icon-dashboard {
  color: #b3ff79;
  font-size: 20px;
  cursor: pointer;
}

.dash_filter_para {
  margin-bottom: 0px;
  color: #bac2cc;
}

.dash_filter_date_input {
  position: relative;
  padding: 10px;
  padding-left: 40px;
  background: url("../assets/images/calender_icon.png") no-repeat;
  background-position: 10px center;
  background-size: 20px 20px;
  background-color: rgba(255, 255, 255, 0.066);
  border: none;
  border-radius: 7px;
  color: white;
}

.dash_filter_date_input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.filter_clear_btn {
  background-color: black;
  color: #b3ff79;
  border: 1px solid #b3ff79;
  padding: 10px;
  text-align: center;
  border-radius: 7px;
}

.filter_submit_btn {
  background-color: #b3ff79;
  color: black;
  border: 1px solid #b3ff79;
  padding: 10px;
  text-align: center;
  border-radius: 7px;
}

.filter_date_div {
  background-color: #232222;
  padding: 40px;
  border-radius: 10px;
}

.dash_filter_top {
  border-bottom: 1px solid #303030;
  padding-bottom: 10px;
}

.express_head {
  color: white;
}

.expert_profile_tip {
  height: auto;
  width: 40px;
}

.expert_ratings {
  margin-top: 20px;
  margin-left: 10px;
}

.timer_div {
  border: 1px solid #dc4453;
  height: 20px;
  padding: 2px 5px;
  width: 68px;
  border-radius: 5px;
}

.tips_timer {
  font-size: 10px;
  color: #dc4453;
}

.fa-clock {
  font-size: 11px;
  color: #dc4453;
  margin-top: 2px;
}

.date_para {
  color: #bac2cc;
  font-size: 10px;
  margin-bottom: 0px;
}

.time_para {
  color: #bac2cc;
  font-size: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.stock_img {
  height: auto;
  width: 30px;
}

.current_stock_price {
  color: #2dcf3d;
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 20px;
}

.live_indiator_para {
  color: #2dcf3d;
  font-weight: 600;
  margin-bottom: 6px;
  margin-left: 37px;
}

.stock_name_para {
  color: #bac2cc;
}

.buy_price_para {
  background-color: #b3ff79;
  border: none;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  padding: 10px 0px;
}

.buy_para {
  background-color: transparent;
  border: 1px solid #b3ff79;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  padding: 10px 0px;
  color: #b3ff79;
}

.div_card_parent {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  padding: 20px;
  border-radius: 7px;
}

.scenerio_para {
  background-color: #b3ff79;
  padding: 5px 18px;
  border-radius: 18px;
}

.targets_amount {
  width: 100%;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
  border: none;
  border-radius: 7px;
  padding: 10px;
  padding-left: 15px;
  color: white;
  text-align: center;
}

.express_preview_model {
  padding: 25px;
  border-radius: 5px;
  background-color: #000;
}

/* .Modal-View {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 85vh;
} */
.tips_close-btn {
  background-color: #b3ff79;
  text-align: center;
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 8px 0px;
  width: 37rem;
}

.tip_previews_model {
  background-color: #000;
  overflow-y: scroll;
  height: 85vh;
}

.modal-close-btn2 {
  /* position: absolute;
  z-index: 100;
  top: 12px;
  right: 40px;
  font-size: 1.3rem;
  color: black;
  background-color: #b3ff79;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; */
  display: none;
}

.chart_div_tip_model {
  width: 100%;
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1245) 0%,
    rgba(255, 255, 255, 0.066) 99.66%
  );
}

.expert-main-page {
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 5px;
  overflow-x: hidden;
  padding: 0px 20px;
}

.exp_stock {
  position: relative;
}
/* 
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #b3ff79;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #b3ff79;
  width: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3ff79;
  width: 5px;
} */

.dropdown-menu li {
  cursor: pointer;
}

.form-control:disabled {
  background-color: transparent;
}

.profile-image-card {
  color: #60ffcb;
  width: 5rem;
  height: 5rem;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.modal-logo {
  width: 10rem;
  height: auto;
  margin-bottom: 1rem;
}

.home-close-btn {
  position: absolute;
  color: white;
  top: -5px;
  right: -5px;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-message {
  line-height: 1.4;
}
.mailtotag a {
  text-decoration: none !important;
  color: white;
}
.mailtotag a:hover {
  color: #60ffcb;
}
.term_privay p:hover {
  color: #60ffcb;
}
