@media only screen and (min-width: 1400px) {
  .about-left-img {
    width: 31%;
    height: auto;
    z-index: 100;
  }

  .ban-bottom {
    width: 25rem;
    height: auto;
    position: absolute;
    bottom: -68px;
  }

  .expert-right-img {
    width: 59%;
    height: auto;
    z-index: 100;
  }

  .get_touch_left {
    margin-top: 85px;
  }

  .user-left-img {
    width: 65%;
    height: auto;
    z-index: 100;
  }
}

@media only screen and (max-width: 992px) {
  .banner-head1 {
    font-size: 45px;
  }

  .banner-head2 {
    font-size: 45px;
  }

  .logo-img {
    width: 125px;
    height: auto;
  }

  .banner-right {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .banner-left {
    margin-top: 0px;
    padding: 3rem;
  }

  .ban-bottom {
    width: 80%;
    height: auto;
    position: absolute;
    bottom: -40px;
  }

  .mobile-ban-bottom {
    width: 68%;
    height: auto;
    position: absolute;
    bottom: 12px;
  }

  .ban-left-img {
    width: 60%;
  }

  .parent-section {
    height: auto;
  }

  .section2-right {
    padding: 40px 0px 0px;
  }

  .aboutus-section {
    padding: 40px 0px;
  }

  .about-right {
    margin-top: 80px !important;
  }

  .user-card1 {
    width: auto;
    margin-right: 30px;
    margin-bottom: 30px;
  }

  .line2-horizontal {
    width: 125px;
    height: 3px;
  }

  .platform-usage-section {
    padding: 40px 0px;
  }

  .platform-center {
    margin: 20px 0px;
  }

  .user-card {
    width: auto;
  }
  /* .section2-left {
    padding-top: 0px;
  } */
  .user-right {
    margin-top: 40px;
  }

  .user-line-vertical {
    top: 6%;
    right: -9%;
    height: 130px;
    width: 2px;
  }
  .footer_img {
    width: 150px;
    height: auto;
    margin-bottom: 10px;
  }
  .user-right-line {
    display: none;
  }
  .animation_overflow_section2 {
    padding: 50px 0px 50px;
  }
  .user-left-line {
    display: none;
  }

  .user-section {
    padding: 40px 0px;
  }

  .Expert-section {
    padding: 40px 0px;
  }

  .abt-head4 {
    margin-bottom: 0px !important;
  }

  .expert-left-line {
    display: none;
  }

  .expert-right-line {
    display: none;
  }

  .market-section {
    padding: 40px 0px;
  }

  .market-left {
    display: flex;
    justify-content: center;
  }

  .watchdemo-right {
    margin-left: 0px;
    margin-top: 20px;
  }

  .faq_section {
    padding: 50px 0px 50px;
  }

  .our_journey_left {
    display: none;
  }

  .our_journey_right {
    display: none;
  }

  .expert_faq {
    margin-top: 30px;
  }

  .ourjourney_section {
    padding: 40px 0px;
  }

  .user-left {
    margin-bottom: 40px;
  }

  .get_touch_right {
    margin-top: 70px;
  }

  .get_in_touch_section {
    padding: 40px 0px;
  }

  .footer_top {
    display: block;
  }

  .footer_icon {
    margin-top: 15px !important;
  }

  .footer_bototm {
    display: block;
    margin-top: 25px;
  }

  .create-btn {
    margin-left: 0px;
  }

  .journey_line_left {
    display: none;
  }

  .journey_line_right {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .content-body {
    display: flex;
    flex-grow: 1;
    overflow: scroll;
  }
  .main-page {
    overflow: visible;
    padding: 0px 10px;
  }
  .sidebar {
    width: auto;
    overflow: visible;
    padding: 0px 6px;
  }
  .sidebar-menu-wrapper:hover,
  .clicked {
    padding: 0px 10px;
  }
  .get_touch_left {
    margin-top: 20px !important;
  }
  .sec-left-img {
    padding: 0px;
  }
}
